import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["dropdown", "button", "buttonSpan", "submit"];
  outsideClickListener = null;
  connect() {
    this.changeDropdownColor();
    this.outsideClickListener = (event) => {
      if (
        !this.dropdownTarget.contains(event.target) &&
        !this.buttonTarget.contains(event.target)
      ) {
        this.hideDropdown();
      }
    };

    document.addEventListener("click", this.outsideClickListener);
  }
  changeDropdownColor() {
    const path = window.location.pathname;
    const colorClass = this.dropdownColorBasedOnPath(path);
    this.dropdownTarget.classList.add(colorClass);
    this.buttonTarget.classList.add(colorClass);
  }
  dropdownColorBasedOnPath(path) {
    // Add your logic here to determine the color based on the path
    if (path.includes("body")) {
      return "bg-BrightGreen";
    } else if (path.includes("mind")) {
      return "bg-BrightPurple";
    } else if (path.includes("career")) {
      return "bg-BrightBlue";
    } else {
      // Default color if the path doesn't match any specific page
      return "bg-BrightGreen";
    }
  }
  disconnect() {
    // Remove the event listener when the controller is disconnected
    if (this.outsideClickListener) {
      document.removeEventListener("click", this.outsideClickListener);
    }
  }
  hideDropdown() {
    this.dropdownTarget.classList.add("hidden");
  }
  changeLocale(event) {
    const locale = event.target.getAttribute("data-value");
    console.log(`Selected locale: ${locale}`);
    this.buttonSpanTarget.innerText = event.target.innerText;
    this.toggleDropdown(); // Close the dropdown
  }
  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden");
  }
}

import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["loaderBtn"];

  connect() {
    this.loaderBtnTarget.classList.add("hidden");
  }

  loadPage() {
    // Show loader
    this.loaderBtnTarget.classList.remove("hidden");
    this.loaderBtnTarget.classList.add("absolute", "top-0");
  }
}

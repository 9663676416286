import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["box", "boxTwo", "boxThree", "boxFour"];
  connect() {
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener("scroll", this.handleScroll);
    const initialOne = `translateX(0px) translateY(0px)`;
    const initialTwo = `translateX(-300px) translateY(0px)`;
    this.boxTarget.style.transform = initialOne;
    this.boxTwoTarget.style.transform = initialTwo;
    this.boxThreeTarget.style.transform = initialOne;
    this.boxFourTarget.style.transform = initialTwo;
  }
  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    const movingDiv = document.getElementById("targetDiv");
    const scrollY = window.scrollY;
    const divOffsetTop = movingDiv.offsetTop;
    const divHeight = movingDiv.clientHeight;
    const initialOne = `translateX(0px) translateY(0px)`;
    const initialTwo = `translateX(-300px) translateY(0px)`;
    if (scrollY >= divOffsetTop - divHeight) {
      // Correct the condition here
      const w = window.scrollY;
      const translateXOne = -w * 0.1 - 250;
      const translateY = w * 0;
      const translateXTwo = w * 0.1 - 300;
      const f = `translateX(${translateXOne}px) translateY(${translateY}px)`;
      const g = `translateX(${translateXTwo}px) translateY(${translateY}px)`;
      this.boxTarget.style.transform = f;
      this.boxTwoTarget.style.transform = g;
      this.boxThreeTarget.style.transform = f;
      this.boxFourTarget.style.transform = g;
    } else {
      this.boxTarget.style.transform = initialOne;
      this.boxTwoTarget.style.transform = initialTwo;
      this.boxThreeTarget.style.transform = initialOne;
      this.boxFourTarget.style.transform = initialTwo;
    }
  }
}

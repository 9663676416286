import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener(
      "turbo:before-fetch-request",
      this.appendTurboToHeaders
    );
  }

  disconnect() {
    window.removeEventListener(
      "turbo:before-fetch-request",
      this.appendTurboToHeaders
    );
  }

  appendTurboToHeaders(event) {
    let { headers } = event.detail.fetchOptions || {};

    if (headers) {
      headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(
        ", "
      );
    }
  }
}

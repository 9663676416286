import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navButton"];
  // customer--you-mobile-btn
  connect() {
    try {
      this.navButtonTargets.forEach((ele) => {
        ele.setAttribute(
          "data-action",
          "click->customer--you-mobile-btn#toggleButton"
        );
      });
    } catch (error) {
      console.log(error);
    }
  }

  // "border-BrightGreen text-BrightGreen" : "border-DarkStone text-DarkStone"
  toggleButton() {
    for (let tgt of this.navButtonTargets) {
      tgt.classList.remove("border-BrightGreen", "text-BrightGreen");
      tgt.classList.add("border-DarkStone", "text-DarkStone");
    }
    console.log(event.currentTarget);
    event.currentTarget.classList.remove("border-DarkStone", "text-DarkStone");
    event.currentTarget.classList.add("border-BrightGreen", "text-BrightGreen");
  }
}

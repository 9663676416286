import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitBtn"];

  connect() {
    try {
      // Bind quiz question id to the page url
      const frame = document.querySelector("#quiz_list");
      if (frame) {
        const newUrl = frame.getAttribute("src");
        window.history.pushState({ turbolinks: true }, "", newUrl);
      }
    } catch (err) {
      console.error("Error in connect:", err);
    }
  }

  submitForm() {
    console.log("submitting");
    try {
      this.submitBtnTarget.click();
    } catch (err) {
      console.error("Error in submitForm:", err);
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "categoriesSelect",
    "getAudiencesEndpoint",
    "audiencesContainer",
    "audiencesList",
    "audienceTemplate",
  ];

  initialize() {
    this.categoriesSelectTarget.setAttribute(
      "data-action",
      "change->programs#changeAudiances"
    );
  }

  changeAudiances() {
    var categoryId = event.target.value;
    var audiencesEndpoint =
      this.getAudiencesEndpointTarget.value + "?category_id=" + categoryId;

    fetch(audiencesEndpoint)
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          var newSelectElements = this.audiencesListTarget.querySelectorAll(
            "select.new-select-true"
          );

          var templateSelectElements =
            this.audienceTemplateTarget.querySelectorAll(
              "select.new-select-true"
            );

          // Create a new options HTML string by mapping the `options` array to `<option>` elements
          const optionsHtml = data.options
            .map(
              ([label, value]) => `<option value="${value}">${label}</option>`
            )
            .join("");

          newSelectElements.forEach((selectElement) => {
            // Replace the existing options with the new options HTML
            selectElement.innerHTML = optionsHtml;
          });

          templateSelectElements.forEach((selectElement) => {
            // Replace the existing options with the new options HTML
            selectElement.innerHTML = optionsHtml;
          });

          this.audiencesContainerTarget.innerHTML = "";
        }
      });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "ssmlContent",
    "locale",
    "audioPlayer",
    "audio",
    "loader",
    "fetchLocale",
    "languageSelect",
    "errorSpan",
  ];

  connect() {
    const select = this.element; // Get the select element
    select.addEventListener("change", this.languageSelected.bind(this));
  }

  languageSelected(event) {
    const targetElementId = event.target.id;
    const selectedOption = event.target.selectedOptions[0];
    const languageId = selectedOption.value;
    var endPoint = this.fetchLocaleTarget.getAttribute("attr-fetch-locale-url");
    if (endPoint && targetElementId) {
      endPoint = endPoint + "?language_id=" + languageId;
      fetch(`${endPoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": this.getMetaValue("csrf-token"),
        },
        dataType: "json",
      })
        .then((response) => response.json())
        .then((result) => {
          this.localeTarget.innerHTML = "";
          result.data.forEach((item) => {
            this.localeTarget.innerHTML += ` <select data-admin--preview-target="locale" attr-preview-url="<%=admin_preview_audio_path%>">
                                                      <option value="${item.id}">${item.name}</option>
                                                    </select>`;
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  loadAudio() {
    event.preventDefault();
    this.audioTarget.classList.remove("hidden");
    const ssmlContent = this.ssmlContentTarget.value;
    const locale = this.localeTarget.value;
    const urlPreview = this.localeTarget.getAttribute("attr-preview-url");
    this.loaderTarget.classList.remove("hidden");
    this.hitAudioUrl(ssmlContent, urlPreview, locale);
  }

  async hitAudioUrl(ssmlContent, urlPreview, locale) {
    var endPoint = urlPreview;
    try {
      const response = await fetch(endPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": this.getMetaValue("csrf-token"),
        },
        body: JSON.stringify({ ssmlContent, locale }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.code === 200) {
          console.log(data.audio_url);
          this.audioTarget.src = data.audio;
          this.loaderTarget.classList.add("hidden");
          this.errorSpanTarget.classList.add("hidden");
        } else {
          console.error("Server response code is not 200");
          this.loaderTarget.classList.add("hidden");
          this.errorSpanTarget.classList.remove("hidden");
        }
      } else {
        console.error("Network request failed with status:", response.status);
        this.loaderTarget.classList.add("hidden");
        this.errorSpanTarget.classList.remove("hidden");
      }
    } catch (error) {
      console.log(error);
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}

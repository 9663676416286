import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["confirmDeleteAccount"];

  connect() {
    try {
      window.addEventListener("popstate", this.handlePopstate.bind(this));
      this.loadAnimation();
    } catch (error) {
      // console.error("An error occurred in the connect method:", error);
    }
  }

  openDeleteAccountModal(event) {
    try {
      this.confirmDeleteAccountTargets.forEach((element) => {
        element.classList.remove("hidden");
        this.modalOpened();
      });
    } catch (err) {}
  }
  closeDeleteAccountModal() {
    try {
      this.confirmDeleteAccountTargets.forEach((element) => {
        element.classList.add("hidden");
        this.modalClosed();
      });
    } catch (err) {}
  }
  handleKeyDown = (event) => {
    if (event.key === "Escape") {
      this.closeDeleteAccountModal();
    }
  };

  modalOpened() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  modalClosed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
}

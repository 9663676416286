import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel"];

  toggle(event) {
    const panelTarget = event.currentTarget
      .closest(".max-w-screen-lg")
      .querySelector(".accordion__content");

    if (panelTarget) {
      this.panelTargets.forEach((panel) => {
        if (panel !== panelTarget) {
          panel.classList.remove("hidden");
        }
      });

      panelTarget.classList.toggle("hidden");
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { language: String, id: String };

  changeLanguage(event) {
    const selector = `[data-controller='admin--localized-field'][data-admin--localized-field-id-value='${this.idValue}']`;
    const inputs = document.querySelectorAll(
      `${selector} input.inline-block, ${selector} textarea.inline-block`
    );

    inputs.forEach((input) => {
      input.classList.replace("inline-block", "hidden");
    });
    document
      .querySelector(
        `[data-controller='admin--localized-field'][data-admin--localized-field-id-value='${this.idValue}'] [data-language=${event.target.value}]`
      )
      .classList.replace("hidden", "inline-block");
  }
}

import { Controller } from "@hotwired/stimulus";
import LottieScrollTrigger from "../utilities/lottie_scroll_trigger";

export default class extends Controller {
  static targets = ["container"];

  static values = { path: String }

  connect() {
    LottieScrollTrigger({
      target: this.containerTarget,
      path: this.pathValue,
      end: '+=2000',
      pin: false,
      start: '-200 bottom'
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "input", "submit"]

  static values = { sitekey: String }

  // token valid for 2 minutes - https://developers.google.com/recaptcha/docs/v3#placement_on_your_website
  tokenValidFor = 120000 // milliseconds

  submit(event) {
    // recaptcha loaded in production only, see custom_login.html.erb
    if (typeof grecaptcha == 'undefined') return

    // if recaptcha token still valid, just continue the submit event normally
    const tokenValid = this.tokenGeneratedAt + this.tokenValidFor > Date.now()
    if (tokenValid) return

    // if recaptcha token expired, stop submit, generate a new token, submit again
    event.preventDefault()
    this.submitTarget.setAttribute("disabled", "disabled")
    this.submitTarget.classList.add("disabledBtn")

    grecaptcha.ready((() => {
      grecaptcha.execute(this.sitekeyValue).then(((token) => {
        this.tokenGeneratedAt = Date.now()
        this.inputTarget.value = token
        this.formTarget.requestSubmit()
      }).bind(this));
    }).bind(this));
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["progressDiv"];
  connect() {
    try {
      var percentValue =
        this.progressDivTarget.getAttribute("attr-percent-value");
      var prePercent = this.progressDivTarget.getAttribute(
        "attr-pre-percent-value"
      );
      this.progressDivTarget.style.animation = "increaseLeft 0.3s ease";
      this.progressDivTarget.style.left = `calc(-100% + ${percentValue}%)`;
      this.progressDivTarget.addEventListener(
        "animationiteration",
        this.handleAnimationIteration.bind(this)
      );
      const anchorTags = this.element.querySelectorAll("a");

      // Do something with each anchor tag
      anchorTags.forEach((anchor) => {
        anchor.addEventListener("click", this.handleAnchorClick.bind(this));
      });

      const styleSheet = document.styleSheets[0];
      styleSheet.insertRule(
        `
            @keyframes increaseLeft {
                from {
                    left: calc(-100% + ${prePercent}%);
                }
                to {
                    left: calc(-100% + ${percentValue}%);
                }
            }
        `,
        styleSheet.cssRules.length
      );
    } catch (err) {}
    this.showContinueButton();
  }

  handleAnchorClick(event) {
    // Handle anchor click event here
    var rightpercentValue =
      this.progressDivTarget.getAttribute("attr-percent-value");
    var rightprePercent = this.progressDivTarget.getAttribute(
      "attr-pre-percent-value"
    );
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(
      `
            @keyframes increaseLeft {
                from {
                    right: calc(0% + ${rightpercentValue}%);
                }
                to {
                    right: calc(0% + ${rightprePercent}%);
                }
            }
        `,
      styleSheet.cssRules.length
    );
  }

  handleAnimationIteration() {
    try {
      this.progressDivTarget.style.left = "0";
    } catch (err) {}
  }

  showContinueButton() {
    const checkboxes = this.element.querySelectorAll("input[type='checkbox']");
    const submitButton = this.element.querySelector(
      "[data-form-multiple-target='submit']"
    );

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        if (this.isAnyCheckboxChecked(checkboxes)) {
          submitButton.classList.remove("hidden");
        } else {
          submitButton.classList.add("hidden");
        }
      });
    });
  }

  isAnyCheckboxChecked(checkboxes) {
    return Array.from(checkboxes).some((checkbox) => checkbox.checked);
  }
}

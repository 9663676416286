import InputValidationController from "./input_validation_controller";
import intlTelInput from "intl-tel-input/build/js/intlTelInputWithUtils"

export default class extends InputValidationController {
  static values = {
    initialCountry: { type: String, default: 'auto' }
  }

  connect() {
    this.iti = intlTelInput(this.inputTarget, {
      hiddenInput: () => ({ phone: 'phone' }), // create a hidden input for the full phone number (E164/MSISDN)
      initialCountry: this.initialCountryValue || "auto",
      geoIpLookup: function(success, failure) {
        fetch("https://ipapi.co/json")
          .then(function(res) { return res.json(); })
          .then(function(data) { success(data.country_code); })
          .catch(function() { failure(); });
      }
    });
    super.connect()
  }

  validate() {
    return this.iti.isValidNumber()
  }
}

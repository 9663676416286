import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "input",
    "submit",
    "checkOtpPath",
    "errormsg",
    "resendModal",
  ];

  openResendModal(event) {
    try {
      this.resendModalTargets.forEach((element) => {
        element.classList.remove("hidden");
        this.modalOpened();
      });
    } catch (err) {}
  }
  closeResendModal() {
    try {
      this.resendModalTargets.forEach((element) => {
        element.classList.add("hidden");
        this.modalClosed();
      });
    } catch (err) {}
  }
  handleKeyDown = (event) => {
    if (event.key === "Escape") {
      this.closeResendModal();
    }
  };

  modalOpened() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  modalClosed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  filledClasses() {
    return ["text-BrightGreen", "border-BrightGreen", "focus:border-BrightGreen"]
  }

  emptyClasses() {
    return ["text-WarmBlack", "border-BrightStone", "focus:border-WarmBlack"]
  }

  errorClasses() {
    return ["text-BrightRed", "border-BrightRed", "focus:border-BrightRed"]
  }

  setFilledClass(input) {
    input.classList.remove(...this.errorClasses());
    input.classList.remove(...this.emptyClasses());
    input.classList.add(...this.filledClasses());
  }

  setEmptyClass(input) {
    input.classList.remove(...this.errorClasses());
    input.classList.remove(...this.filledClasses());
    input.classList.add(...this.emptyClasses());
  }

  setErrorClass(input) {
    input.classList.remove(...this.filledClasses());
    input.classList.remove(...this.emptyClasses());
    input.classList.add(...this.errorClasses());
  }

  focusOnNext(input) {
    const nextIndex = this.inputTargets.indexOf(input) + 1;
    if (nextIndex < this.inputTargets.length) {
      const nextInput = this.inputTargets[nextIndex]
      nextInput.focus();
      nextInput.select();
    } else {
      this.checkInputsAndSubmit()
    }
  }

  focusOnPrevious(input) {
    const previousIndex = this.inputTargets.indexOf(input) - 1;
    if (previousIndex >= 0) {
      const previousInput = this.inputTargets[previousIndex];
      previousInput.focus();
      previousInput.select();
    }
  }

  handleInput(event) {
    if (this.hasErrormsgTarget) this.errormsgTarget.classList.add("hidden");

    const input = event.target;
    const sanitizedValue = input.value.replace(/[^0-9]/g, "");
    input.value = sanitizedValue.substring(0, 1);

    if (sanitizedValue.length === this.inputTargets.length) {
      this.fillInAllDigitsAndSubmit(sanitizedValue);
    } else if (sanitizedValue.length > 0) {
      this.setFilledClass(input);
      this.focusOnNext(input)
    } else {
      this.setEmptyClass(input);
    }
  }

  handleKeyUp(event) {
    const input = event.target;

    if (event.key === 'Backspace' && input.value.length === 0) {
      this.focusOnPrevious(input)
    }
  }

  handlePaste(event) {
    const pastedText = event.clipboardData.getData("text");
    const sanitizedText = pastedText.replace(/[^0-9]/g, "");

    if (sanitizedText.length === this.inputTargets.length) {
      this.fillInAllDigitsAndSubmit(sanitizedText);
    }
  }

  fillInAllDigitsAndSubmit(otp) {
    if (otp.length !== this.inputTargets.length) return

    this.inputTargets.forEach((input, index) => {
      input.value = otp[index];
      this.setFilledClass(input);
    });

    this.checkInputsAndSubmit();
  }

  checkInputsAndSubmit() {
    // It's important to remove focus from the inputs, or Safari auto-fill will continue typing in values in the wrong boxes
    document.activeElement.blur()

    const filledInputs = this.inputTargets.filter(
      (input) => input.value.trim() !== ""
    );
    if (filledInputs.length === this.inputTargets.length) {
      this.submitTarget.click();
    } else {
      const firstEmptyInput = this.inputTargets.find(
        (input) => input.value.trim() === ""
      );
      if (firstEmptyInput) {
        firstEmptyInput.focus();
      }
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.animation = bodymovin.loadAnimation({
      container: this.containerTarget,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.containerTarget.getAttribute("data-animation-path"),
    });
  }
}

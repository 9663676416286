import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "revealed", "hidden"];

  connect() {
    this.#updateButton();
  }

  toggle(event) {
    this.contentTarget.classList.toggle("hidden");
    this.#updateButton();
  }

  #updateButton() {
    const isHidden = this.contentTarget.classList.contains("hidden");
    this.revealedTarget.hidden = !isHidden;
    this.hiddenTarget.hidden = isHidden
  }
}

import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { resource: String };

  select() {
    var app_id = event.currentTarget.selectedOptions[0].value;
    window.location.href = "/admin/" + app_id + "/" + this.resourceValue;
  }
}

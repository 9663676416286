import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitBtn", "inputHidden"];

  connect() {
    try {
      // Attach an event listener to the radio buttons

      this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
        radio.addEventListener("change", () => this.toggleButtonClass());
      });

      // Initially set the class based on the checked radio button
      this.toggleButtonClass();
    } catch (error) {}
  }

  toggleButtonClass() {
    // Find the checked radio button
    const priceYearId = this.submitBtnTarget.getAttribute(
      "data-price-year-value"
    );
    const priceMonthId = this.submitBtnTarget.getAttribute(
      "data-price-month-value"
    );
    const checkedRadio = this.element.querySelector(
      'input[type="radio"]:checked'
    );
    // Remove or add the 'disabledBtn' class based on the checked radio button

    if (checkedRadio && checkedRadio.checked) {
      this.submitBtnTargets.forEach((button) => {
        button.classList.remove("disabledBtn");
      });
      if (checkedRadio.id == "plan-annual") {
        this.inputHiddenTarget.value = priceYearId;
      } else {
        this.inputHiddenTarget.value = priceMonthId;
      }
    } else {
      this.submitBtnTargets.forEach((button) => {
        button.classList.add("disabledBtn");
      });
    }
  }
}

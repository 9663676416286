import { Controller } from "@hotwired/stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static values = {
    track: String,
    testVersion: String,
    app: String,
  };

  initialize() {
    mixpanel.init("ff6c5385d00fd14703efa255a9416e9e", {
      persistence: "localStorage",
    });
  }

  connect() {
    mixpanel.track_pageview({
      testVersion: this.testVersionValue,
      app: this.appValue,
    });

    if (this.trackValue) {
      mixpanel.track(this.trackValue, { app: this.appValue });
    }
  }
}

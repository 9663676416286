// ai-search
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitBtn"];

  connect() {
    console.log("ai-search connected");
  }

  handleSubmitStart(event) {
    // event.preventDefault();

    // Update the submit button text to "Please wait"
    this.submitBtnTarget.value = "Please wait...";
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 10000 },
  };

  connect() {
    this.timeout = setTimeout(() => this.element.click(), this.delayValue);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "menu",
    "header",
    "footer",
    "textSec",
    "link",
    "loaderBtn",
  ];

  connect() {
    try {
      this.loaderBtnTarget.classList.add("hidden");
    } catch (err) {}
    this.changeHeaderColor();
    this.changeFooterColor();
    this.changeTextColor();
  }

  toggle() {
    this.menuTarget.classList.toggle("hidden");
  }

  changeHeaderColor() {
    const path = window.location.pathname;
    const colorClass = this.determineHeaderColorBasedOnPath(path);
    this.headerTarget.classList.add(colorClass);
  }

  changeFooterColor() {
    const path = window.location.pathname;
    const colorClass = this.determineFooterColorBasedOnPath(path);
    this.footerTarget.classList.add(colorClass);
  }

  changeTextColor() {
    const path = window.location.pathname;
    const colorClass = this.determineTextColorBasedOnPath(path);
    this.textSecTarget.classList.add(colorClass);
  }

  determineHeaderColorBasedOnPath(path) {
    // Add your logic here to determine the color based on the path
    if (path.includes("body")) {
      return "bg-BrightGreen";
    } else if (path.includes("mind")) {
      return "bg-BrightPurple";
    } else if (path.includes("career")) {
      return "bg-BrightBlue";
    } else {
      // Default color if the path doesn't match any specific page
      return "bg-BrightPurple";
    }
  }

  determineFooterColorBasedOnPath(path) {
    // Add your logic here to determine the color based on the path
    if (path.includes("body")) {
      return "bg-BrightGreen";
    } else if (path.includes("mind")) {
      return "bg-BrightPurple";
    } else if (path.includes("career")) {
      return "bg-BrightBlue";
    } else {
      // Default color if the path doesn't match any specific page
      return "bg-BrightGreen";
    }
  }

  determineTextColorBasedOnPath(path) {
    // Add your logic here to determine the color based on the path
    if (path.includes("body")) {
      return "text-BrightTurquoise";
    } else if (path.includes("mind")) {
      return "text-BrightOrange";
    } else if (path.includes("career")) {
      return "text-SkyBlue";
    } else {
      // Default color if the path doesn't match any specific page
      return "text-BrightOrange";
    }
  }

  get headerTarget() {
    return document.getElementById("header"); // Adjust the ID accordingly
  }

  get footerTarget() {
    return document.getElementById("footer"); // Adjust the ID accordingly
  }

  get textSecTarget() {
    return document.getElementById("textSec"); // Adjust the ID accordingly
  }

  loadPage() {
    this.loaderBtnTarget.classList.remove("hidden");
  }
}

// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

// Instead of using require.context
const channels = import.meta.glob("./*_channel.js");

// To load all channels
Object.keys(channels).forEach((key) => {
  channels[key]().then((module) => {
    // Use the module as needed
    console.log(module);
  });
});

import { Controller } from "@hotwired/stimulus";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Request permission to send notifications then get the Firebase Cloud Messaging token.
// Reference: https://firebase.google.com/docs/cloud-messaging/js/client?authuser=2
export default class extends Controller {
  static targets = ["trigger", "form"];

  connect() {
    this.#setupFirebase();
    this.#getToken();
  }

  #setupFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyATWv5Jb3titKsNirFI_RDCEkFoiAh9nYs",
      authDomain: "viva-410814.firebaseapp.com",
      projectId: "viva-410814",
      storageBucket: "viva-410814.appspot.com",
      messagingSenderId: "614238495652",
      appId: "1:614238495652:web:6a6d82a0e7f4003af19731",
      measurementId: "G-5FXND5NNN9",
    };

    const app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(app);
    this.vapidKey = "BGPNNr3-EmuUWNWJa0237MFo4sOYQ0HiE78ufRSNcuRzZSrBfjd0L1cOfseDCYLiuwszL5PLL_EsrYr1ajtOgQU";
  }

  // Modern browsers require that notification permission is requested from a user event handler, such as a click
  requestPermission() {
    if (Notification.permission == "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") this.#getToken();
      });
    }
  }

  #getToken() {
    if (Notification.permission == "granted") {
      getToken(this.messaging, { vapidKey: this.vapidKey })
        .then(this.#handleToken.bind(this))
    }
  }

  #handleToken(currentToken) {
    this.formTarget.token.value = currentToken;
    this.formTarget.time_zone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.formTarget.requestSubmit();
  }
}

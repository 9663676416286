import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { appId: String, resource: String };

  select() {
    var id = event.currentTarget.selectedOptions[0].value;
    if (id > 0) {
      window.location.href =
        "/admin/" + this.appIdValue + "/" + this.resourceValue + "/" + id;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    try {
      this.animationPath = this.containerTarget.getAttribute(
        "data-animation-path"
      );
      this.animation = null;
      this.animationPlayed = false;
    } catch (err) {}
  }

  connect() {
    try {
      window.addEventListener("popstate", this.handlePopstate.bind(this));
      this.loadAnimation();
    } catch (error) {
      // console.error("An error occurred in the connect method:", error);
    }
  }

  handlePopstate(event) {
    window.location.reload();
  }

  loadAnimation() {
    this.animation = bodymovin.loadAnimation({
      container: this.containerTarget,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: this.animationPath,
    });
  }
}

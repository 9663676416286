import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "validIcon",
    "invalidIcon",
    "errorMessage",
    "submit",
  ];

  connect() {
    this.#updateSubmitTarget()
  }

  input() {
    this.#updateSubmitTarget()
    this.#updateStyles()
  }

  submit(event) {
    if (!this.validate()) event.preventDefault()
  }

  #updateStyles() {
    const valid = this.validate()
    this.validIconTarget.classList.toggle("hidden", !valid);
    if (valid && this.hasErrorMessageTarget) this.errorMessageTarget.remove()

    this.wasValid ||= valid
    if (this.wasValid) {
      this.inputTarget.classList.toggle('input-valid', valid);
      this.inputTarget.classList.toggle('input-invalid', !valid);
      this.invalidIconTarget.classList.toggle("hidden", valid);
    }
  }

  #updateSubmitTarget() {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !this.validate()
    }
  }

  // This method should be overridden by subclasses
  validate() {
    return true
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["courseLesson"];

  onClickCourseLesson(event) {
    const app = event.currentTarget.getAttribute("attr-app");
    this.courseLessonTargets.forEach(this.#unhighlightCourseLesson);
    this.#highlightCourseLesson(event.currentTarget, app);
  }

  #highlightCourseLesson(element, app) {
    if (app == "viva") {
      element.classList.add("border-BrightGreen", "text-BrightGreen");
    } else if (app == "career") {
      element.classList.add("border-BrightBlue", "text-BrightBlue");
    }
  }

  #unhighlightCourseLesson(element) {
    element.classList.remove("border-BrightGreen", "border-BrightBlue", "text-BrightGreen", "text-BrightBlue");
  }
}

import { EventBus } from "./utilities/event_bus";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "counter", "maxCharacters"];

  connect() {
    this.countCharacters();
    EventBus.on("count-characters", () => this.countCharacters());
  }

  countCharacters() {
    let characters = this.nameTarget.value.length;

    let gender = /{gender}/g;
    let female = /{female}/g;

    let gender_matches = this.nameTarget.value.match(gender);
    if (gender_matches && gender_matches.length > 0) {
      gender_matches.forEach((x) => (characters = characters - 7));
    }

    let female_matches = this.nameTarget.value.match(female);
    if (female_matches && female_matches.length > 0) {
      female_matches.forEach((x) => (characters = characters - 7));
    }

    this.counterTarget.innerText = characters;

    if (characters > this.maxCharactersTarget.innerText) {
      this.counterTarget.classList.add("font-bold", "text-orange");
    } else {
      this.counterTarget.classList.remove("font-bold", "text-orange");
    }
  }
}

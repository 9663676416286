import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    nextUrl: String,
  }

  connect() {
    this.timeout = setTimeout(this.#visitNextUrl.bind(this), 5000);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  #visitNextUrl() {
    Turbo.visit(this.nextUrlValue, { action: "replace" })
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "trigger",
    "timeSettings",
    "progressSettings",
    "lessonSettings",
    "signUpSettings",
    "weeklySettings",
    "customSettings",
  ];

  connect() {
    this.selectTrigger();
  }

  selectTrigger() {
    this.#hideTriggerSettings();
    this.#showTriggerSettings();
  }

  #hideTriggerSettings() {
    this.timeSettingsTarget.classList.add("hidden");
    this.progressSettingsTarget.classList.add("hidden");
    this.lessonSettingsTarget.classList.add("hidden");
    this.signUpSettingsTarget.classList.add("hidden");
    this.weeklySettingsTarget.classList.add("hidden");
    this.customSettingsTarget.classList.add("hidden");
  }

  #showTriggerSettings() {
    switch (this.triggerTarget.value) {
      case "time_based": this.timeSettingsTarget.classList.remove("hidden"); break;
      case "progress_based": this.progressSettingsTarget.classList.remove("hidden"); break;
      case "lesson_based": this.lessonSettingsTarget.classList.remove("hidden"); break;
      case "sign_up_based": this.signUpSettingsTarget.classList.remove("hidden"); break;
      case "weekly_based": this.weeklySettingsTarget.classList.remove("hidden"); break;
      case "custom_based": this.customSettingsTarget.classList.remove("hidden"); break;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    this.animationPath = this.containerTarget.getAttribute(
      "data-animation-path"
    );
    this.eventValue = this.containerTarget.getAttribute("attr-event-value");
    this.animation = null;
    this.animationPlayed = false;
  }

  connect() {
    try {
      window.addEventListener("popstate", this.handlePopstate.bind(this));
      this.loadAnimation();
    } catch (error) {
      // console.error("An error occurred in the connect method:", error);
    }
  }

  handlePopstate(event) {
    window.location.reload();
  }

  loadAnimation() {
    // Load the animation but do not play it immediately
    this.animation = bodymovin.loadAnimation({
      container: this.containerTarget,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: this.animationPath,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMax slice'
      }
    });

    this.animation.addEventListener(
      "complete",
      this.handleAnimationComplete.bind(this)
    );
  }

  handleAnimationComplete() {
    const customerLink = this.containerTarget.getAttribute(
      "attr-customer-link-value"
    );
    if (this.eventValue == "subs_animations") {
      window.location.href = customerLink;
    }
  }
}

import NestedForm from "@stimulus-components/rails-nested-form";

export default class extends NestedForm {
  static targets = [
    "audiencesList",
    "selectedCategory",
    "getAudiencesEndpoint",
  ];

  confirmRemove(e) {
    if (confirm("Are you sure?") == true) {
      this.remove(e);
    } else {
    }
  }

  addRecord() {
    // call the default nested-form add action to add a new record
    var selectedCategoryId = this.selectedCategoryTarget.value;
    console.log(selectedCategoryId);
    if (selectedCategoryId != "") {
      super.add(event);
      // get the newly added record element
      var newSelectElements = this.audiencesListTarget.querySelectorAll(
        "select.new-select-true"
      );
      console.log(newSelectElements);
      // Get the index of the last node
      const lastIndex = newSelectElements.length - 1;

      // Get the last node
      const lastNode = newSelectElements[lastIndex];

      var audiencesEndpoint =
        this.getAudiencesEndpointTarget.value +
        "?category_id=" +
        selectedCategoryId;

      fetch(audiencesEndpoint)
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 200) {
            // Create a new options HTML string by mapping the `options` array to `<option>` elements
            const optionsHtml = data.options
              .map(
                ([label, value]) => `<option value="${value}">${label}</option>`
              )
              .join("");

            lastNode.innerHTML = optionsHtml;
          }
        });
    }
  }
}

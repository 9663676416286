import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menuButton", "all"];

  connect() {
    try {
      this.allTarget.setAttribute(
        "data-action",
        "click->button-color#allBtnActive"
      );
      this.menuButtonTargets.forEach((ele) => {
        ele.setAttribute("data-action", "click->button-color#toggleClass");
      });

      // this.allBtnActive();
      this.activeByCategory();
    } catch (err) {
      console.log(err);
    }
  }

  toggleClass(event) {
    this.resetBtns();

    var color = event.currentTarget.getAttribute("attr-color");
    event.currentTarget.classList.remove(`text-${color}`); //      add color
    event.currentTarget.classList.add(`bg-${color}`, "text-BrightStone"); //      add color
  }

  allBtnActive() {
    this.resetBtns();
    this.allTargets.forEach((tgt) => {
      tgt.classList.remove("text-BrightOrange");
      tgt.classList.add("bg-BrightOrange", "text-LightOrange");
    });
  }

  allBtnInactive() {
    this.allTargets.forEach((tgt) => {
      tgt.classList.remove(
        "bg-BrightOrange",
        "text-BrightStone",
        "text-LightStone",
        "text-LightOrange"
      );
      tgt.classList.add("text-BrightOrange");
    });
  }

  resetBtns() {
    this.allBtnInactive();
    this.menuButtonTargets.forEach((tgt) => {
      var color = tgt.getAttribute("attr-color");
      tgt.classList.remove(`bg-${color}`); //   remove color
      tgt.classList.add(`text-${color}`);
    });
  }

  activeByCategory() {
    const paramsId = this.menuButtonTarget.getAttribute("attr-params-id");
    this.menuButtonTargets.forEach((ele) => {
      const color = ele.getAttribute("attr-color");
      const catId = ele.getAttribute("attr-cat-id");

      if (paramsId === catId) {
        ele.classList.remove(`text-${color}`);
        ele.classList.add(`bg-${color}`, "text-BrightStone");
      } else {
        ele.classList.remove(`bg-${color}`, "text-BrightStone");
        ele.classList.add(`text-${color}`);
      }
    });
  }
}

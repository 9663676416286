import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['rating', 'message']

  connect() {
    this.messageTarget.classList.add('hidden')
  }

  revealMessage() {
    this.ratingTarget.classList.add('hidden')
    this.messageTarget.classList.remove('hidden')
  }
}
